<template>
  <a
    href="#"
    @click.prevent.stop="emitClick"
    :class="($style.infoLink, { [$style.bigText]: bigLink })"
  >
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    bigLink: {
      type: Boolean,
      require: false,
      default: true
    }
  },

  methods: {
    emitClick() {
      this.$emit('clickLink');
    }
  }
};
</script>

<style module>
.infoLink {
  display: block;
  text-decoration: underline;
  font-weight: 500;
}

.bigText {
  font-size: 1.3125rem;
}
</style>
