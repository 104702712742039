<template>
  <b-form-textarea
    id="textarea"
    :value="text"
    v-bind="$attrs"
    @input="updateValue"
    class="input_color"
  ></b-form-textarea>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    text: {
      type: String,
      require: false
    }
  },

  methods: {
    updateValue(event) {
      this.$emit('input', event);
    }
  }
};
</script>

<style></style>
