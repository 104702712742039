<template>
  <ServicesDescription
    :isPageWrapper="true"
    :isTitle="isTitle"
    :isFooter="isVisible"
    @connectService="sendRequest"
  >
    <template #body>
      <info-block>
        <template #text>
          <info-link @clickLink="isVisible = !isVisible">
            {{ title }}
          </info-link>
        </template>
      </info-block>
      <b-collapse v-model="isVisible" style="margin-top: 2rem">
        <b-row class="lg-align-start align-center">
          <b-col md="11" lg="11" xl="8">
            <b-form id="option-request">
              <div v-for="(item, index) in layoutData.Inputs" :key="index">
                <b-form-group
                  label-text-align="center"
                  :label="item.Label"
                  label-cols-sm="6"
                  label-align="left"
                  label-align-sm="left"
                >
                  <BaseTextInput
                    v-if="createFormItems(item.Tag, 'input', item.Type, 'text')"
                    v-model="inputValue[index].value"
                    :disabled="index === 1 && isDisabled"
                  />

                  <BaseTextarea
                    v-if="createFormItems(item.Tag, 'textarea')"
                    v-model="inputValue[index].value"
                    :placeholder="$options.TEXTAREA_PLACEHOLDER"
                    rows="6"
                  />

                  <BaseSelect
                    v-if="createFormItems(item.Tag, 'select')"
                    v-model="inputValue[0].value"
                    :options="inputValue[0].options"
                  />
                </b-form-group>
              </div>
            </b-form>
          </b-col>
        </b-row>
      </b-collapse>
    </template>
  </ServicesDescription>
</template>

<script>
import InfoBlock from '@/components/shared/InfoBlock.vue';
import BaseSelect from '@/components/shared/inputs/BaseSelect.vue';
import BaseTextInput from '@/components/shared/inputs/BaseTextInput.vue';
import BaseTextarea from '@/components/shared/inputs/BaseTextarea.vue';
import ServicesDescription from '@/components/shared/ServicesDescription.vue';
import InfoLink from '@/components/shared/InfoLink.vue';
import { catchFunction, processResponse, ShowErrorToUser } from '@/additional_helpers/helpers';
import URL from '@/basUrl';
import { sendCustomerRequest, getServiceData } from '@/api/common';

export default {
  TEXTAREA_PLACEHOLDER: 'Укажите дополнительную информацию, которая необходима для подключения',

  props: {
    title: {
      type: String,
      require: true,
      default: 'Подключить интеграцию'
    }
  },

  data() {
    return {
      isTitle: false,
      isVisible: false,
      layoutData: [],
      inputValue: [],
      responseStatus: ''
    };
  },

  components: {
    ServicesDescription,
    BaseSelect,
    BaseTextInput,
    BaseTextarea,
    InfoLink,
    InfoBlock
  },

  computed: {
    // Проверяет указана ли пользовательская интеграция, которая отсутствует в предложенном списке
    isDisabled() {
      if (!this.inputValue.map((el) => el.value).includes('Другая CRM')) return true;
      return false;
    }
  },

  created() {
    // Получает форму заявки
    getServiceData(URL.CTI_FORM)
      .then((result) => {
        this.layoutData = result;
        this.createModel();
      })
      .catch((e) => catchFunction(e));
  },

  methods: {
    // Скрывает
    createFormItems(elemTag, tag, elemType, type) {
      return elemTag === tag && elemType === type;
    },

    // Валидация формы
    validation() {
      const baseValue = this.layoutData.Inputs[0].Attributes.option_selected;
      const userSelectCri = this.inputValue[0].value;
      const values = this.inputValue.map((el) => el.value === '');
      const notDisabledItems = values.filter((el, index) => index !== 1);

      if (userSelectCri === 'Другая CRM') {
        if (values.includes(true) || userSelectCri === baseValue) {
          return {
            status: false,
            text: 'Заполните все поля формы'
          };
        }
        return true;
      }

      if (notDisabledItems.includes(true) || userSelectCri === baseValue) {
        return {
          status: false,
          text: 'Заполните все поля формы, кроме "Другая интеграция"'
        };
      }

      return { status: true };
    },

    // Создает массив для корректного отображения формы
    createModel() {
      this.inputValue = this.layoutData.Inputs.map((el) => {
        if (el.Options) {
          // получает список option для select
          const optionsList = el.Options.map((item) => item.Value);
          // преобразует option для использования в bootstrap
          const optionsArray = optionsList.map((option) => {
            if (option === 'Выберите один из вариантов') {
              // формирует объект готовый для отображения
              return { text: option, value: option, disabled: true };
            }
            return { text: option, value: option };
          });
          return {
            key: el.Name,
            options: optionsArray,
            value: optionsArray[0].text
          };
        }

        return { key: el.Name, value: '' };
      });
    },

    // Формирует объект для отправки запроса на подключение
    createRequestObject() {
      const x = this.inputValue.map((el) => [el.key, el.value]);

      // Формируем поля, которые должны быть в объекте
      // 80 - id формы и он не меняется для этого запроса
      const requestObject = {
        OptionId: this.layoutData.OptionId,
        Values: Object.fromEntries(x)
      };

      return requestObject;
    },

    // Отправляет заявку на сервер
    sendRequest() {
      if (!this.validation().status) {
        ShowErrorToUser(this.validation().text);
        return;
      }

      const row = JSON.stringify(this.createRequestObject());

      sendCustomerRequest(URL.OPTION_CONNECT, row)
        .then((result) => {
          processResponse(result);
          this.responseStatus = result.status;
        })
        .catch((e) => catchFunction(e));
    }
  }
};
</script>

<style></style>
