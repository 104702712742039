<template>
  <div :class="$style.wrapper" class="dflex">
    <div :class="$style.icon">
      <slot name="icon">
        <b-icon icon="plug" alt="Иконка 'информация'"></b-icon>
      </slot>
    </div>
    <div :class="$style.text">
      <slot name="text"></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" module>
.wrapper{
  padding: 0.25rem 1rem;
  border: 1px $lightGrey dashed;
  background: $white;
  text-align: left;
}

.icon {
  width: 1.5rem;

  & > svg {
    height: 100%;
    width: 100%;
  }
}

.text {
  padding-left: 1rem;
  width: calc(100% - 1.5rem);
}
</style>
